/* eslint-disable complexity */
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PortalSelectArticlesPage from './PortalSelectArticlesPage';
import { bindActionCreators, compose } from 'redux';
import { goToStep, setCurrentStep } from '../../../actions/stepper_actions';
import {
  validateSale,
  fetchExtraQuotas,
  toggleBaseErrorModal,
  getScoringCategories,
  getMaxFinancialPlanbyCategories,
  getVoluntaryInsuranceLayerTwoInfo,
  resetLayerTwoInfo,
  getMegaQuota,
  setMegaQuota,
  resetMegaQuota,
  setScoringQuota,
  setApplyScoring,
  resetProp
} from '../../../actions/portal_financing_actions';
import { newAlert } from '../../../actions/alert_actions';
import { doctypes } from '../../../constants/enums';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import { removeFirstZeros } from '../../../helpers/utilityFunctions';
import { validateCampaign, resetQueryCampaign } from '../../../actions/campaign_actions';
import { fetchSecureCreditInstalmentsPercentages } from '../../../actions/secure_credit_instalments_percentage_actions';
import { fetchArticlesNode } from '../../../actions/article_actions';
import uuid from 'uuid/v4';

function mapStateToProps(state) {
  const pf = state.portalFinancing;
  const unmaterializedPromissoryProcessId = pf.unmaterialized_promissory_process_id;
  const studyLevels = _get(
    state,
    'globalSetting.orgData.get_levels_of_studies'
  );
  const orgData = _get(state, 'globalSetting.orgData');
  const articlesData = {
    articles: state.articles.articles,
    supplierId: state.portalFinancing.portalPOS[0].attributes.supplier.id
      ? state.portalFinancing.portalPOS[0].attributes.supplier.id
      : state.authentication.currentUser.supplier.id,
    globalConfig: {
      voluntary_insurance_value: pf.voluntary_insurance_value,
      voluntary_insurance_value_grace_period:
        pf.voluntary_insurance_value_grace_period,
      voluntary_insurance_layer_two_value_grace_period:
        pf.voluntary_insurance_layer_two_value_grace_period,
      motorbike_voluntary_insurance_active:
        pf.motorbike_voluntary_insurance_active,
      motorbike_voluntary_insurance_value:
        pf.motorbike_voluntary_insurance_value,
      motorbike_voluntary_insurance_grace_period_active:
        pf.motorbike_voluntary_insurance_grace_period_active,
      motorbike_voluntary_insurance_grace_period_value:
        pf.motorbike_voluntary_insurance_grace_period_value,
      motorbike_voluntary_insurance_layer_two_grace_period_value:
        pf.motorbike_voluntary_insurance_layer_two_grace_period_value,
      obligatory_insurance_rate: pf.obligatory_insurance_rate,
      interest_rate: pf.interest_rate,
      lower_interest_rate: pf.lower_interest_rate,
      excluded_lines_from_general_interest_rate: pf.excluded_lines_from_general_interest_rate,
      urban_and_rural_interest_rate: pf.urban_and_rural_interest_rate,
    },
    userConfig: {
      articles: pf.selectedArticles,
      insurances: pf.insurances,
      articleInstallments: pf.articleInstallments,
      downPayment: pf.downPayment,
      observations: pf.observations
    },
    articleInstallments: pf.article_installments,
    scoringExclusionCategories: pf.scoring_exclusion_categories || [],
    isCommercialSale: pf.is_commercial_sale,
    contractLocationType: pf.is_urban_contract ? 'urban' : 'rural',
  };
  const saleDetails = {
    sale: {
      sold_at: moment(pf.saleDate, 'YYYY-MM-DD').format('DD-MM-YYYY'),
      salesman_id: Number.parseInt(pf.clerk),
      point_of_sale_id: Number.parseInt(pf.point_of_sale),
      sale_channel_id: Number.parseInt(pf.sale_channel),
      site_delivery: false // TODO: (FASE 2)
    },
    first_invoice: pf.invoice1,
    second_invoice: pf.invoice2,
    debtorContract: pf.debtor_contract_number,
    debtorBirthdate: pf.debtor_birthdate,
    debtorStratum: pf.stratum,
    cosignerBirthdate: pf.cosigner_birthdate,
    uniquePromissory: pf.promissory ? pf.promissory.code : null,
    uniquePromissoryType: pf.promissory ? pf.promissory.type : null,
    extraQuotas: pf.is_commercial_sale ? [] : pf.extraQuotas,
    campaignValidationResponse: state.campaign.validationResponse,
    requestCampaignValidationSend: state.requestState.requestCampaignValidationSend,
    gracePeriodCampaignIds: _get(
      state,
      'globalSetting.orgData.grace_period_campaign_ids'
    ),
    gracePeriodDeffered: _get(
      state,
      'globalSetting.orgData.grace_period_deffered'
    ),
    isGracePeriodActive: _get(
      state,
      'globalSetting.orgData.is_grace_period_active'
    )
  };
  const canValidateCampaing = state.authentication.currentUser.canValidateCampaign;
  const canCheckVoluntaryInsuranceLayerTwoBuyability = state.authentication.currentUser.canCheckVoluntaryInsuranceLayerTwoBuyability;
  const canCheckMegaQuotaInfo = state.authentication.currentUser.canCheckMegaQuotaInfo;
  if (pf.promissory && !pf.moveQuotaData.contract) {
    const debtor = pf.promissory.debtor;
    const cosigner = pf.promissory && pf.promissory.cosigner;
    return {
      ...articlesData,
      supplier: state.authentication.currentUser.supplier,
      requestArticleListSend: state.requestState.requestArticleListSend,
      requestMegaQuotaSend: state.requestState.requestMegaQuotaSend,
      megaQuotaInfo: pf.megaQuotaInfo,
      useMegaQuota: pf.useMegaQuota,
      hasPets: _get(pf, 'validatedSaleDetails.hasPets'),
      usedQuota: pf.used_quota,
      usedQuotaByClient: pf.usedQuotaByClient,
      isScoringUsed: pf.isScoringUsed,
      scoringQuotaByMegaquota: pf.scoringQuotaByMegaquota,
      scoringResponseId: pf.scoringResponseId,
      availableQuota: pf.available_quota,
      secureCreditInstalmentsPercentageList: state.secureCreditInstalmentsPercentage.list,
      isSecureSale: pf.isSecureSale,
      secureSaleArticleId: _get(
        state,
        'globalSetting.orgData.secure_sale_article_id'
      ),
      isInstallmentConfirmationActive: _get(
        state,
        'globalSetting.orgData.is_installment_confirmation_active'
      ),
      allowScoringWithExtraQuota: _get(
        state,
        'globalSetting.orgData.allow_scoring_with_extra_quota'
      ),
      secureSaleArticle: { key: uuid(), id: state.globalSetting.orgData.secure_sale_article_id, amount: 0, quantity: '1' },
      voluntaryInsuranceLayerTwoArticle: { key: uuid(), id: orgData.voluntary_insurance_layer_two_article_id, amount: orgData.voluntary_insurance_layer_two_value, quantity: '1' },
      secureSaleMaxQuota: state.globalSetting.orgData.secure_sale_max_quota,
      validateForeignIdentificationExpirationDateActive: state.globalSetting.orgData.validate_foreign_identification_expiration_date_active,
      invoiceValidationData:
        state.globalSetting.orgData.sale_invoice_validation_step,
      canValidateCampaing,
      canCheckVoluntaryInsuranceLayerTwoBuyability,
      canCheckMegaQuotaInfo,
      routes: state.stepper.routes,
      process: state.stepper.process,
      originalProcess: state.stepper.originalProcess,
      nextStep: state.stepper.nextStep,
      portalNext: state.stepper.portalNext,
      promissoryNext: state.stepper.promissoryNext,
      applyScoring: pf.applyScoring,
      sellers: pf.sellers.filter(seller => !seller.attributes.generic),
      orgSellerDataAuthorization: _get(orgData, 'get_seller_data_authorization'),
      isBlackListActive: _get(orgData, 'get_blacklist_active'),
      cardifSalesmanId: _get(pf, 'validatedSaleDetails.sale.cardif_salesman_id'),
      isSendVoluntaryInsuranceSalesmanActive: _get(orgData, 'is_send_voluntary_insurance_salesman_in_sale_active'),
      voluntaryInsuranceMaxAge: _get(
        state,
        'globalSetting.orgData.voluntary_insurance.max_age'
      ),
      minInstallmentsToSellVoluntaryInsurance: _get(
        state,
        'globalSetting.orgData.min_installments_to_sell_voluntary_insurance'
      ),
      article_types: _get(state, 'globalSetting.orgData.article_types'),
      cardifArticle: _get(
        state,
        'globalSetting.orgData.voluntary_insurance.cardif.article'
      ),
      cardifSupplier: _get(
        state,
        'globalSetting.orgData.voluntary_insurance.cardif.supplier'
      ),
      giftCardConfig: _get(state, 'globalSetting.orgData.gift_card'),
      ...saleDetails,
      gnpGasApplianceSaleEvaluation:
        state.portalFinancing.gnp_gas_appliance_evaluation,
      debtor: {
        newName: pf.id_name,
        newLastname: pf.id_first_last_name + ' ' + pf.id_second_last_name,
        name: debtor.name,
        last_name: debtor.last_name,
        marital_status: debtor.marital_status,
        debtor_doctype_id:
          debtor.type_of_identification &&
          doctypes.find(o => o.name === debtor.type_of_identification).id,
        type_of_identification: debtor.type_of_identification,
        identification: removeFirstZeros(debtor.identification),
        id_expedition_place: debtor.id_expedition_place,
        expeditionDate: pf.id_debtor_expedition_date || pf.id_expedition_date || '',
        id_expedition_date: moment(
          debtor.id_expedition_date,
          'YYYY-MM-DD'
        ).format('DD-MM-YYYY'),
        idExpirationDate: pf.id_expiration_date ? moment(
          pf.id_expiration_date,
          'YYYY-MM-DD'
        ).format('DD-MM-YYYY') : '',
        gender: debtor.gender === 'M' ? 4 : debtor.gender === 'F' ? 1 : 9,
        level_of_studies:
          debtor.level_of_studies && studyLevels &&
          studyLevels.find(o => o.name === debtor.level_of_studies).id,
        email: debtor.email,
        phone: debtor.phone,
        mobile: debtor.mobile,
        debtorNeighbourhood: _get(pf, 'neighborhood.description', ''),
        address: debtor.address,
        type_of_house: debtor.type_of_house,
        occupation: debtor.occupation || '',
        profession_id: _get(debtor, 'profession.id', ''),
        company_name: debtor.company_name || '',
        date_of_birth: pf.debtor_birthdate
      },
      cosigner: cosigner
        ? {
          contract_id: pf.cosigner_contract_number
            ? pf.cosigner_contract_number
            : null,
          name: _get(cosigner, 'name', ''),
          last_name: _get(cosigner, 'last_name', ''),
          marital_status: _get(cosigner, 'marital_status', ''),
          cosigner_doctype_id: _get(cosigner, 'type_of_identification')
            ? doctypes.find(
              o => o.name === cosigner.type_of_identification
            ).id
            : '',
          type_of_identification: _get(
            cosigner,
            'type_of_identification',
            ''
          ),
          identification: _get(cosigner, 'identification', ''),
          id_expedition_place: _get(cosigner, 'id_expedition_place', ''),
          idExpirationDate: pf.dc_expiration_date && pf.dc_expiration_date !== '--' ? moment(
            pf.dc_expiration_date,
            'YYYY-MM-DD'
          ).format('DD-MM-YYYY') : '',
          expeditionDate: pf.id_cosigner_expedition_date || pf.id_expedition_date_cosigner || '',
          id_expedition_date: _get(cosigner, 'id_expedition_date', '')
            ? moment(cosigner.id_expedition_date, 'YYYY-MM-DD').format(
              'DD-MM-YYYY'
            )
            : null,
          gender:
            cosigner.gender === 'M' ? 4 : cosigner.gender === 'F' ? 1 : 9,
          level_of_studies: _get(cosigner, 'level_of_studies') && studyLevels
            ? studyLevels.find(o => o.name === cosigner.level_of_studies)
              .id
            : '',
          email: _get(cosigner, 'email', ''),
          phone: _get(cosigner, 'phone', ''),
          mobile: _get(cosigner, 'mobile', ''),
          cosignerNeighbourhood: '',
          address: _get(cosigner, 'address', ''),
          type_of_house: _get(cosigner, 'type_of_house', ''),
          occupation: _get(cosigner, 'occupation', ''),
          profession_id: _get(cosigner, 'profession.id', ''),
          company_name: cosigner.company_name,
          date_of_birth: pf.cosigner_birthdate
        }
        : null,
      invoiceValidationSkipped: pf.invoiceValidationSkipped,
      categoriesUsedToScoringMaximumValue: state.globalSetting.orgData.categories_to_use_scoring_extra_quota_maximun_value,
      megaquotaAllowedCategoryIds: state.globalSetting.orgData.mega_quota_allowed_category_ids,
      megaQuotaMinValueToSellInSelectedCategories: state.globalSetting.orgData.mega_quota_min_value_to_sell_in_selected_categories,
      scoringQuota: pf.scoringQuota,
      assignedQuota: pf.assigned_quota,
      voluntaryInsuranceLayerTwoValue: state.globalSetting.orgData.voluntary_insurance_layer_two_value,
      voluntaryInsuranceLayerTwoArticleId: state.globalSetting.orgData.voluntary_insurance_layer_two_article_id,
      voluntaryInsuranceLayerTwoActiveBySale: state.globalSetting.orgData.is_voluntary_insurance_layer_two_active_by_sale,
      isVoluntaryInsuranceLayerTwoActive: state.globalSetting.orgData.is_voluntary_insurance_layer_two_active,
      voluntaryInsuranceLayerTwoName: state.globalSetting.orgData.voluntary_insurance_layer_two_name,
      minInstallmentsToSellVoluntaryInsuranceLayerTwo: state.globalSetting.orgData.voluntary_insurance_layer_one_minimum_validity,
      voluntaryInsurance: pf.voluntaryInsurance,
      sellVoluntaryInsuranceInSecureSaleIsActive: pf.sell_voluntary_insurance_in_secure_sale_is_active,
      unmaterializedPromissoryProcessId
    };
  } else {
    return {
      ...articlesData,
      supplier: state.authentication.currentUser.supplier,
      requestArticleListSend: state.requestState.requestArticleListSend,
      requestMegaQuotaSend: state.requestState.requestMegaQuotaSend,
      megaQuotaInfo: pf.megaQuotaInfo,
      useMegaQuota: pf.useMegaQuota,
      hasPets: _get(pf, 'validatedSaleDetails.hasPets'),
      usedQuota: pf.used_quota,
      usedQuotaByClient: pf.usedQuotaByClient,
      isScoringUsed: pf.isScoringUsed,
      scoringQuotaByMegaquota: pf.scoringQuotaByMegaquota,
      scoringResponseId: pf.scoringResponseId,
      availableQuota: pf.available_quota,
      secureCreditInstalmentsPercentageList: state.secureCreditInstalmentsPercentage.list,
      isSecureSale: pf.isSecureSale,
      secureSaleArticle: { key: uuid(), id: state.globalSetting.orgData.secure_sale_article_id, amount: 0, quantity: '1' },
      voluntaryInsuranceLayerTwoArticle: { key: uuid(), id: orgData.voluntary_insurance_layer_two_article_id, amount: orgData.voluntary_insurance_layer_two_value, quantity: 1 },
      secureSaleArticleId: state.globalSetting.orgData.secure_sale_article_id,
      isInstallmentConfirmationActive: _get(
        state,
        'globalSetting.orgData.is_installment_confirmation_active'
      ),
      allowScoringWithExtraQuota: _get(
        state,
        'globalSetting.orgData.allow_scoring_with_extra_quota'
      ),
      secureSaleMaxQuota: state.globalSetting.orgData.secure_sale_max_quota,
      validateForeignIdentificationExpirationDateActive: state.globalSetting.orgData.validate_foreign_identification_expiration_date_active,
      invoiceValidationData:
        state.globalSetting.orgData.sale_invoice_validation_step,
      canValidateCampaing,
      canCheckVoluntaryInsuranceLayerTwoBuyability,
      canCheckMegaQuotaInfo,
      routes: state.stepper.routes,
      process: state.stepper.process,
      originalProcess: state.stepper.originalProcess,
      nextStep: state.stepper.nextStep,
      portalNext: state.stepper.portalNext,
      promissoryNext: state.stepper.promissoryNext,
      applyScoring: pf.applyScoring,
      sellers: pf.sellers.filter(seller => !seller.attributes.generic),
      orgSellerDataAuthorization: _get(orgData, 'get_seller_data_authorization'),
      isBlackListActive: _get(orgData, 'get_blacklist_active'),
      cardifSalesmanId: _get(pf, 'validatedSaleDetails.sale.cardif_salesman_id'),
      isSendVoluntaryInsuranceSalesmanActive: _get(orgData, 'is_send_voluntary_insurance_salesman_in_sale_active'),
      voluntaryInsuranceMaxAge: _get(
        state,
        'globalSetting.orgData.voluntary_insurance.max_age'
      ),
      article_types: _get(state, 'globalSetting.orgData.article_types'),
      cardifArticle: _get(
        state,
        'globalSetting.orgData.voluntary_insurance.cardif.article'
      ),
      cardifSupplier: _get(
        state,
        'globalSetting.orgData.voluntary_insurance.cardif.supplier'
      ),
      giftCardConfig: _get(state, 'globalSetting.orgData.gift_card'),
      ...saleDetails,
      minInstallmentsToSellVoluntaryInsurance: _get(
        state,
        'globalSetting.orgData.min_installments_to_sell_voluntary_insurance'
      ),
      gnpGasApplianceSaleEvaluation:
        state.portalFinancing.gnp_gas_appliance_evaluation,
      debtor: {
        newName: pf.id_name,
        newLastname: pf.id_first_last_name + ' ' + pf.id_second_last_name,
        name: pf.name,
        last_name: pf.last_name,
        debtor_doctype_id:
          pf.debtor_identification_type &&
          doctypes.find(o => o.name === pf.debtor_identification_type).id,
        type_of_identification: pf.debtor_identification_type,
        identification: pf.debtor_identification,
        idExpirationDate: pf.id_expiration_date && pf.id_expiration_date !== '--' ? moment(
          pf.id_expiration_date,
          'YYYY-MM-DD'
        ).format('DD-MM-YYYY') : '',
        address: pf.address,
        date_of_birth: pf.debtor_birthdate,
        debtorNeighbourhood: _get(pf, 'neighborhood.description', ''),
        expeditionDate: pf.id_debtor_expedition_date || pf.id_expedition_date || '',
        contract_id: pf.debtor_contract_number
      },
      cosigner: pf.cosigner_identification
        ? {
          contract_id: pf.cosigner_contract_number,
          identification: pf.cosigner_identification,
          cosigner_doctype_id:
            pf.cosigner_identification_type &&
            doctypes.find(o => o.name === pf.cosigner_identification_type).id,
          type_of_identification: pf.cosigner_identification_type,
          idExpirationDate: pf.dc_expiration_date && pf.dc_expiration_date !== '--' ? moment(
            pf.dc_expiration_date,
            'YYYY-MM-DD'
          ).format('DD-MM-YYYY') : '',
          cosignerDoctypeText: pf.cosigner_identification_type,
          expeditionDate: pf.id_cosigner_expedition_date || pf.id_expedition_date_cosigner || '',
          date_of_birth: pf.cosigner_birthdate
        }
        : null,
      invoiceValidationSkipped: pf.invoiceValidationSkipped,
      categoriesUsedToScoringMaximumValue: state.globalSetting.orgData.categories_to_use_scoring_extra_quota_maximun_value,
      megaquotaAllowedCategoryIds: state.globalSetting.orgData.mega_quota_allowed_category_ids,
      megaQuotaMinValueToSellInSelectedCategories: state.globalSetting.orgData.mega_quota_min_value_to_sell_in_selected_categories,
      scoringQuota: pf.scoringQuota,
      assignedQuota: pf.assigned_quota,
      voluntaryInsuranceLayerTwoValue: state.globalSetting.orgData.voluntary_insurance_layer_two_value,
      voluntaryInsuranceLayerTwoArticleId: state.globalSetting.orgData.voluntary_insurance_layer_two_article_id,
      voluntaryInsuranceLayerTwoActiveBySale: state.globalSetting.orgData.is_voluntary_insurance_layer_two_active_by_sale,
      isVoluntaryInsuranceLayerTwoActive: state.globalSetting.orgData.is_voluntary_insurance_layer_two_active,
      voluntaryInsuranceLayerTwoName: state.globalSetting.orgData.voluntary_insurance_layer_two_name,
      minInstallmentsToSellVoluntaryInsuranceLayerTwo: state.globalSetting.orgData.voluntary_insurance_layer_one_minimum_validity,
      voluntaryInsurance: pf.voluntaryInsurance,
      sellVoluntaryInsuranceInSecureSaleIsActive: pf.sell_voluntary_insurance_in_secure_sale_is_active
    };
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    fetchArticlesNode: bindActionCreators(fetchArticlesNode, dispatch),
    validateSale: bindActionCreators(validateSale, dispatch),
    validateCampaign: bindActionCreators(validateCampaign, dispatch),
    resetQueryCampaign: bindActionCreators(resetQueryCampaign, dispatch),
    resetLayerTwoInfo: bindActionCreators(resetLayerTwoInfo, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch),
    toggleBaseErrorModal: bindActionCreators(toggleBaseErrorModal, dispatch),
    fetchExtraQuotas: bindActionCreators(fetchExtraQuotas, dispatch),
    fetchSecureCreditInstalmentsPercentages: bindActionCreators(fetchSecureCreditInstalmentsPercentages, dispatch),
    getScoringCategories: bindActionCreators(getScoringCategories, dispatch),
    getMaxFinancialPlanbyCategories: bindActionCreators(getMaxFinancialPlanbyCategories, dispatch),
    getVoluntaryInsuranceLayerTwoInfo: bindActionCreators(getVoluntaryInsuranceLayerTwoInfo, dispatch),
    getMegaQuota: bindActionCreators(getMegaQuota, dispatch),
    setMegaQuota: bindActionCreators(setMegaQuota, dispatch),
    resetMegaQuota: bindActionCreators(resetMegaQuota, dispatch),
    goToStep: bindActionCreators(goToStep, dispatch),
    setScoringQuota: bindActionCreators(setScoringQuota, dispatch),
    setApplyScoring: bindActionCreators(setApplyScoring, dispatch),
    resetProps: bindActionCreators(resetProp, dispatch),
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PortalSelectArticlesPage);
